import {API} from "@aws-amplify/api";

const API_NAME = 'CertificatesApi';
const API_PATH = '/api/get'

const CertificateApiService = {
    getCertificate(certificateId, issuer) {
        return API.get(API_NAME, API_PATH, {queryStringParameters: {certificateId, issuer}}).then(response => {
            if (response?.status === true) return response.data;
            return false;
        }).catch(error => {
            console.error(error);
            return false;
        });
    }
};

export default CertificateApiService;