import awsExports from "../aws-exports";

export const APP_VERSION = 'v1.4.3';
export const i18N_VERSION = APP_VERSION;
export const LATEST_POLICIES_VERSION = 'v1.0.0';

export const IS_PRODUCTION_ENV = awsExports.aws_cloud_logic_custom[0].endpoint.endsWith('prod');

export const STARTUP_MUNDI_URL = 'https://startupmundi.com';

export const CERTIFICATES_URL = IS_PRODUCTION_ENV
    ? 'https://certificates.startupmundi.com'
    : 'https://dev.certificates.startupmundi.com';


if(window)
    window.APP_VERSION = APP_VERSION;