import {jsPDF} from "jspdf";
import PoppinsRegular from "./fonts/poppins-regular";
import PoppinsBold from "./fonts/poppins-bold";

const callAddFontRegular = function () {
    this.addFileToVFS('Poppins.ttf', PoppinsRegular);
    this.addFont('Poppins.ttf', 'Poppins', 'normal');
};

const callAddFontBold = function () {
    this.addFileToVFS('Poppins-Bold.ttf', PoppinsBold);
    this.addFont('Poppins-Bold.ttf', 'Poppins-Bold', 'bold');
};

jsPDF.API.events.push(['addFonts', callAddFontRegular]);
jsPDF.API.events.push(['addFonts', callAddFontBold]);