import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";

import smgx_tournament_player_certificate_v1 from "assets/img/certificates/smgx_tournament_player_v1.svg";

export default function SmgxTournamentPlayerCertificate({certificateData, onLoad}) {
    const {t, i18n} = useTranslation(['certificates/smgx_tournament_player']);
    const svg = useRef();

    const [hasLoadedSvg, setHasLoadedSvg] = useState(false);

    const handleLoad = () => {
        setHasLoadedSvg(true);

        if (onLoad)
            onLoad();
    };

    useEffect(() => {
        if (!hasLoadedSvg || !svg?.current?.contentDocument)
            return;

        const dateLang = i18n.language.startsWith('pt') ? 'pt-BR' : i18n.language;
        const dateFormatter = new Intl.DateTimeFormat(dateLang, {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        const date = dateFormatter.format(new Date(certificateData.date));

        const domEl = svg.current.contentDocument;

        domEl.getElementById('title').textContent = t('title');
        domEl.getElementById('name').textContent = certificateData.name;

        domEl.getElementById('sentence-1').textContent = t('sentence_01');
        domEl.getElementById('sentence-2').textContent = t('sentence_02');
        domEl.getElementById('sentence-3').textContent = t('sentence_03');
        domEl.getElementById('sentence-4').textContent = t('sentence_04');

        domEl.getElementById('duration').textContent = t('duration', {duration: certificateData.dur});
        domEl.getElementById('date').textContent = t('date', {date});

        domEl.getElementById('link-text').textContent = t('link');
        domEl.getElementById('link').textContent = certificateData.certificateUrl;

        const linkUrl = domEl.getElementById('link-url');
        if (linkUrl)
            linkUrl.setAttribute('href', certificateData.certificateUrl);

    }, [i18n.language, svg, hasLoadedSvg, certificateData]);

    return (
        <object className="certificate"
                id="certificate"
                onLoad={handleLoad}
                type="image/svg+xml"
                data={smgx_tournament_player_certificate_v1}
                ref={svg}/>
    );
}


