import React, {useEffect} from 'react';
import {RouterConfig} from "./navigation/RouterConfig";
import {BrowserRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PolicesInfo from "./components/PolicesInfo";

function App() {
    const {t, i18n} = useTranslation(['common']);

    useEffect(() => {
        document.title = t('app_title');
        document.body.style.background = null;
    }, [i18n.language])

    return (
        <>
            <div id="App" className="App">
                <BrowserRouter>
                    <RouterConfig/>
                </BrowserRouter>
            </div>

            <PolicesInfo/>
        </>
    );
}

export default App;
