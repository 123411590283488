export const MS_PER_DAY = 86400000;
export const MS_PER_HOUR = 3600000;
export const MS_PER_MINUTE = 60000;
export const MS_PER_SECOND = 1000;

const Utils = {
    isset(data) {
        return data !== undefined && data !== null && data !== '';
    },

    isFunction(param) {
        return (this.isset(param) && typeof param == "function");
    },

    uuid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            // eslint-disable-next-line no-mixed-operators
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },

    randomFromArray(arr) {
        return arr[~~(Math.random() * arr.length) | 0];
    },

    /* Returns a random integer between min (inclusive) and max (inclusive).*/
    randomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    /* Return an object with the diff between date1 and date2. Ex: {days: 1, time: '00:00:00'} */
    diffBetweenDates(date1, date2) {
        const d1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate(), date1.getUTCHours(), date1.getUTCMinutes(), date1.getUTCSeconds());
        const d2 = Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate(), date2.getUTCHours(), date2.getUTCMinutes(), date2.getUTCSeconds());

        const days = (d1 - d2) % MS_PER_DAY;
        const hours = days % MS_PER_HOUR;
        const minutes = hours % MS_PER_MINUTE;

        return {
            date1IsBefore: (d1 - d2) < 0,
            days: Math.floor((d1 - d2) / MS_PER_DAY),
            hours: Math.floor(days / MS_PER_HOUR),
            minutes: Math.floor(hours / MS_PER_MINUTE),
            seconds: Math.floor(minutes / MS_PER_SECOND),
        }
    },

    utcDateToLocalDate(date) {
        date = new Date(date.replace(/-/g, '/'));
        date = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(date - (new Date()).getTimezoneOffset() * 60 * 1000);
    },

    utcDateTimeToLocalDateStringWithoutSeconds(dateTime, showTimezone = false) {
        const parsedDate = this.utcDateToLocalDate(dateTime);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return `${parsedDate.toLocaleDateString()} - ${parsedDate.toLocaleTimeString().slice(0, -3)} `
            + (showTimezone ? `(${timezone})` : '');
    },
    waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    },

    numberToLetter(number) {
        return (number + 9).toString(36);
    },

    getDoubleDigitsMonthFromDate(date) {
        return ('0' + (date.getMonth() + 1)).slice(-2);
    }
}

export default Utils;