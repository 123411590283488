import React from 'react';
import UnrecoverableError from "../pages/errors/UnrecoverableError";

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: false};
    }

    /**
     * Catches errors not handled by any children
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        this.setState({error: true});
    }

    render() {
        if (this.state.error) {
            return <UnrecoverableError/>
        }

        return this.props.children;
    }
}

export default GlobalErrorBoundary;

