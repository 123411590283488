import React from "react";
import ErrorPageLayout from "./ErroPageLayout/ErrorPageLayout";
import {useTranslation} from "react-i18next";

export default function NotFoundError() {
    const {t} = useTranslation('pages/common/errors', {keyPrefix: 'components.not_found'})

    return (
        <ErrorPageLayout>
            <h1 style={{fontSize: '3rem'}}>{t('texts.title')}</h1>
            <h4>{t('texts.message')}</h4>
        </ErrorPageLayout>
    );
}