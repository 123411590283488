import React, {useEffect, useMemo, useState} from 'react';

import "./Certificate.scss";
import SmgxPlayerCertificate from "./certificates/SmgxPlayerCertificate";

import Header from "./components/Header";
import Footer from "./components/Footer";
import {useTranslation} from "react-i18next";

import {jsPDF} from 'jspdf';
import 'svg2pdf.js';
import {i18nService} from "../../i18n";
import NotFound from "./components/NotFound";
import {useParams} from "react-router-dom";
import CertificateApiService from "../../services/CertificateApiService";
import {STARTUP_MUNDI_URL} from "../../config/CONSTANTS";
import Utils from "../../utils/Utils";

import linkedinButtonIcon from "assets/img/linkedin.svg";
import ReactGA from "react-ga4";
import EsgPlayerCertificate from "./certificates/EsgPlayerCertificate";
import EsgTournamentPlayerCertificate from "./certificates/EsgTournamentPlayerCertificate";
import SmgxTournamentPlayerCertificate from "./certificates/SmgxTournamentPlayerCertificate";

const STATUS = {
    LOADING: 'loading',
    LOADED: 'loaded',
    NOT_FOUND: 'not-found'
};

export const CERTIFICATE_ISSUERS = {
    SMGX_PLAYER: 'SMGX_PLAYER',
    SMGX_TOURNAMENT_PLAYER: 'SMGX_TOURNAMENT_PLAYER',
    ESG_PLAYER: 'ESG_PLAYER',
    ESG_TOURNAMENT_PLAYER: 'ESG_TOURNAMENT_PLAYER'
};

const LINKEDIN_CERTIFICATES_NAMES = {
    [CERTIFICATE_ISSUERS.SMGX_PLAYER]: 'Startup Mundi Game Experience',
    [CERTIFICATE_ISSUERS.SMGX_TOURNAMENT_PLAYER]: 'Game Experience',
    [CERTIFICATE_ISSUERS.ESG_PLAYER]: 'ESG Game Experience',
    [CERTIFICATE_ISSUERS.ESG_TOURNAMENT_PLAYER]: 'ESG Experience'
};

export default function Certificate() {
    const {t, i18n} = useTranslation('pages/common/certificate');
    const {id} = useParams();

    const [status, setStatus] = useState(STATUS.LOADING);
    const [certificateData, setCertificateData] = useState(false);
    const [fileName, setFileName] = useState('certificate');
    const [linkedinUrl, setLinkedinUrl] = useState(false);

    const handleDownload = async () => {
        const doc = new jsPDF({orientation: "landscape"});
        const element = document.getElementById('certificate').contentDocument.children.item(0);
        doc.svg(element).then(() => doc.save(`${fileName}.pdf`));

        ReactGA.event({
            action: 'certificate_download',
            category: 'pdf'
        });
    };

    const handleLoaded = () => {
        setStatus(STATUS.LOADED);
    };

    const handleSetFileName = (name) => {
        name = 'certificate-' + name.replace(/\s+/g, '_').toLowerCase().trim();
        name = name.length > 50 ? name.slice(0, 50) : name;

        setFileName(name);
    };

    const handleGenerateLinkedinUrl = (certificate) => {
        if (!certificate.certificateUrl || !certificate.date)
            setLinkedinUrl(false);

        let name = LINKEDIN_CERTIFICATES_NAMES[certificate.issuer];
        const date = new Date(certificate.date);

        if ([CERTIFICATE_ISSUERS.ESG_TOURNAMENT_PLAYER, CERTIFICATE_ISSUERS.SMGX_TOURNAMENT_PLAYER].includes(certificate.issuer))
            name += ' - ' + t('texts.tournament');

        const url = `http://www.linkedin.com/profile/add`
            + `?startTask=CERTIFICATION_NAME`
            + `&name=${encodeURIComponent(name)}`
            + `&organizationId=11465133`
            + `&issueYear=${date.getFullYear()}`
            + `&issueMonth=${Utils.getDoubleDigitsMonthFromDate(date)}`
            + `&certUrl=${encodeURIComponent(certificate.certificateUrl)}`;

        setLinkedinUrl(url);
    };

    const startupMundiUrl = useMemo(() => {
        if (i18n.language.startsWith('pt')) return STARTUP_MUNDI_URL + '.br';
        return STARTUP_MUNDI_URL;
    }, [i18n.language]);


    useEffect(() => {
        const fetch = async () => {
            const certificateData = await CertificateApiService.getCertificate(id);

            if (certificateData === false || !CERTIFICATE_ISSUERS[certificateData.issuer]) {
                setStatus(STATUS.NOT_FOUND);
                return;
            }

            if (certificateData.name) {
                // capitalize first letter of each word to uppercase
                const nameArray = certificateData.name.split(' ');
                for (let i = 0; i < nameArray.length; i++) {
                    nameArray[i] = nameArray[i].charAt(0).toUpperCase() + nameArray[i].slice(1);
                }

                certificateData.name = nameArray.join(' ');

                handleSetFileName(certificateData.name);
            }

            await i18nService.setLanguage(certificateData.lang || certificateData.language || null);

            handleGenerateLinkedinUrl(certificateData);
            setCertificateData(certificateData);
        };

        fetch();
    }, []);

    return (
        <div id="certificate-page"
             className="h-100 w-100 position-relative bg-dark d-flex flex-column overflow-auto text-white">
            <Header/>

            <div
                className="d-flex flex-fill justify-content-center align-items-center flex-column px-4 position-relative">
                {status === STATUS.LOADING && (
                    <div
                        className="animate__animated animate__faster animate__fadeIn text-center position-absolute m-auto">
                        <div className="lds-dual-ring"/>
                        <h3 className="font-monospace text-uppercase text-white mt-4">{t('texts.loading')}</h3>
                    </div>
                )}

                {certificateData && status !== STATUS.NOT_FOUND && (
                    <div
                        className={"certificates-container d-flex justify-content-center align-items-center flex-column h-100 w-100 " + (status === STATUS.LOADING ? 'hidden' : '')}>

                        {certificateData.issuer === CERTIFICATE_ISSUERS.SMGX_PLAYER && (
                            <SmgxPlayerCertificate certificateData={certificateData} onLoad={handleLoaded}/>
                        )}

                        {certificateData.issuer === CERTIFICATE_ISSUERS.SMGX_TOURNAMENT_PLAYER && (
                            <SmgxTournamentPlayerCertificate certificateData={certificateData} onLoad={handleLoaded}/>
                        )}

                        {certificateData.issuer === CERTIFICATE_ISSUERS.ESG_PLAYER && (
                            <EsgPlayerCertificate certificateData={certificateData} onLoad={handleLoaded}/>
                        )}

                        {certificateData.issuer === CERTIFICATE_ISSUERS.ESG_TOURNAMENT_PLAYER && (
                            <EsgTournamentPlayerCertificate certificateData={certificateData} onLoad={handleLoaded}/>
                        )}


                        <div className="mt-4 text-center">
                            <p className="text-white">
                                {t('texts.generated_by')}
                                <a className="ms-2 text-white" href={startupMundiUrl} target="_blank">
                                    Mundi Games Experience
                                </a>.
                            </p>

                            {linkedinUrl && (
                                <a href={linkedinUrl} target="_blank" rel="noreferrer"
                                   className="btn btn-sm btn-primary text-white px-2 py-0 button-share-on-linkedin ga-linkedin">
                                    <img src={linkedinButtonIcon} alt="Linkedin"/>
                                    <p className="mb-0 d-inline">{t('buttons.add_to_linkedin')}</p>
                                </a>
                            )}

                            <button type="button"
                                    className="btn btn-secondary mx-auto mt-2 mt-md-0 ms-md-2 d-block d-md-inline-block ga-download-pdf"
                                    onClick={handleDownload}>
                                {t('buttons.download_pdf')}
                            </button>
                        </div>
                    </div>
                )}

                {status === STATUS.NOT_FOUND && (<NotFound certificateId={id}/>)}
            </div>

            <Footer/>
        </div>
    );
}


