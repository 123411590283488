import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import {COMMON_ROUTES, TERMS_ROUTES} from "navigation/ROUTES";
import Certificate from "../pages/Certificate/Certificate";
import NotFoundError from "../pages/errors/NotFoundError";


// // terms and policies
const CookiesPolicy = React.lazy(() => import(/* webpackChunkName: "CookiesPolicyChunk" */ "../pages/terms/CookiesPolicy/CookiesPolicy"));
const PrivacyPolicy = React.lazy(() => import(/* webpackChunkName: "PrivacyPolicyChunk" */ "../pages/terms/PrivacyPolicy/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import(/* webpackChunkName: "TermsOfUseChunk" */ "../pages/terms/TermsOfUse/TermsOfUse"));

export const RouterConfig = () => {
    const location = useLocation();

    return (
        <>
            <Routes location={location}>
                {/** common routes */}
                <Route path={COMMON_ROUTES.CERTIFICATE_BY_ID} element={<Certificate/>}/>

                <Route path={TERMS_ROUTES.TERMS_OF_USE} element={<TermsOfUse/>}/>
                <Route path={TERMS_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route path={TERMS_ROUTES.COOKIES_POLICY} element={<CookiesPolicy/>}/>

                {/*  generic 404-Not Found route */}
                <Route path="*" element={<NotFoundError/>}/>
            </Routes>
        </>
    );
};
