import React, {useMemo} from "react";

import "./Header.scss";
import logo from "assets/img/logos/svg/without_tagline_3.svg";
import {useTranslation} from "react-i18next";
import {STARTUP_MUNDI_URL} from "../../../config/CONSTANTS";

export default function Header() {
    const {t, i18n} = useTranslation('pages/common/certificate');

    const startupMundiUrl = useMemo(() => {
        if (i18n.language.startsWith('pt')) return STARTUP_MUNDI_URL + '.br';
        return STARTUP_MUNDI_URL;
    }, [i18n.language]);

    return (<>
            <div id="certificates-header" className="w-100 d-flex justify-content-center bg-dark shadow px-3 py-2 mb-3">
                <div className="w-100 d-flex align-items-center">
                    <a href={startupMundiUrl} target="_blank" referrerPolicy="no-referrer" className="pe-3">
                        <img src={logo} alt="Mundi Games Experience logo"/>
                    </a>

                    <h4 className="text-white mb-0 ps-3 text-break">{t('texts.title')}</h4>
                </div>
            </div>
        </>
    )
}