import React from "react";
import ErrorPageLayout from "./ErroPageLayout/ErrorPageLayout";
import {useTranslation} from "react-i18next";

export default function UnrecoverableError() {
    const {t} = useTranslation('pages/common/errors', {keyPrefix: 'components.fatal_error'})

    return (
        <ErrorPageLayout>
            <h4>{t('texts.title')}</h4>
            <p>{t('texts.message')}</p>
        </ErrorPageLayout>
    );
}