import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "../../../navigation/ROUTES";

export default function Footer() {
    const {t} = useTranslation('common');
    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    return (
        <>
            <footer id="app-footer" className="text-center text-muted w-100 pb-3 pt-4 px-3">
                <p className="mb-0 p-0 small d-inline">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-muted text-decoration-underline ms-1"
                       target="_blank" rel="noreferrer">
                        {t('common:company_name')}
                    </a>. {t('common:rights_reserved')}
                </p>

                <p className="mb-0 mx-2 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                       className="text-muted me-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                       className="text-muted me-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                       className="text-muted">{t('common:privacy_policy')}</a>
                </p>

            </footer>
        </>
    );
}