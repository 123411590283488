import "./i18nLoadingOverlay.scss";
import {useEffect, useState} from "react";

export default function I18nLoadingOverlay({delay = 0}) {
    const [show, setShow] = useState(false);

    // wait some ms before showing to avoid screen flashing
    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    if (!show) return null;

    return (
        <div id="i18n-overlay">
            <h6 className="loading-circles"><span>.</span><span>.</span><span>.</span></h6>
        </div>
    )
}