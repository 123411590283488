import React, {useMemo} from "react";

import logo from "assets/img/logos/svg/without_tagline_3.svg";
import "./ErrorPageLayout.scss";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "navigation/ROUTES";

export default function ErrorPageLayout({children}) {
    const {t} = useTranslation('common');
    const t2 = useTranslation('pages/common/errors', {keyPrefix: 'layout.buttons'}).t;

    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    const handleReload = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    return (
        <div className="min-vw-100 min-vh-100 d-flex flex-column bg-dark text-white error-page">
            <div
                className="flex-fill d-flex justify-content-center align-items-center flex-column mb-4 pb-5 text-center">
                <img alt="Logo" className="mb-4 pb-4" src={logo}/>

                {children}

                <p className="mx-3 mt-5 mb-1 text-white">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-white text-decoration-underline ms-1"
                       target="_blank" rel="noreferrer">
                        Mundi Games Experience</a>. {t('rights_reserved')}
                </p>

                <p className="mb-0 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                       className="text-white me-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                       className="text-white me-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                       className="text-white">{t('common:privacy_policy')}</a>
                </p>
            </div>
        </div>
    );
}