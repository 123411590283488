import React from "react";

import {useTranslation} from "react-i18next";

export default function NotFound({certificateId}) {
    const {t} = useTranslation('pages/common/certificate');

    return (<div className="text-center">
        <h2>{t('texts.not_found')}</h2>
        <h5 className="text-muted text-break">{t('texts.certificate_id', {id: certificateId ?? '-'})}</h5>
    </div>)
}